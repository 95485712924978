import YouTubePlayer from 'youtube-player';

function modalYoutubeVideo() {
	const $modalYoutubeVideoBtn = $('.js-modal-youtube-video-btn');

	if (!$modalYoutubeVideoBtn.length) { return; }

	const $modalYoutubeVideo = $('#modal-youtube-video');
	const $player = $modalYoutubeVideo.find('#player');
	const player = YouTubePlayer($player[0], {
		playerVars: {
			rel: 0,
			showinfo: 0,
		},
	});

	$modalYoutubeVideoBtn.on('click', function() {
		player.loadVideoById($(this).data('video-id'));

		$modalYoutubeVideo.modal('show');

		player.playVideo();
	});

	$modalYoutubeVideo.on('hide.bs.modal', function() {
		player.stopVideo();
	});
}

export default modalYoutubeVideo;
