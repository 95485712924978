/***
 * Module for displaying all simple popups.
****/
const modal = () => {
	const $modalBtn = $('.js-modal-btn');
	if (!$modalBtn.length) { return; }

	$modalBtn.each((i, btn) => {
		const $btn = $(btn);
		const $modal = $($btn.attr('href'));

		$btn.on('click', function(e) {
			e.preventDefault();
			$modal.modal('show');
		});
	});
};

export default modal;
