const GTranslateObserver = () => {

	const hideGoogleTranslateLogo = () => {
		document.querySelectorAll('div').forEach(div => {
			const regex = /^([A-Za-z0-9]{6}-){3,4}[A-Za-z0-9]{6}$/;
			if (regex.test(div.className)) {
				div.style.display = 'none';
			}
		});
	};

	const observer = new MutationObserver(mutations => {
		mutations.forEach(mutation => {
			if (mutation.type === 'childList') {
				hideGoogleTranslateLogo();
			}
		});
	});

	const config = { attributes: true, childList: true, subtree: true };

	$( window ).on( 'load', function() {
		observer.observe(document.body, config);
	});
};

export default GTranslateObserver;
