function multiStepFormAddition() {
	const checkFormPartValidity = ($form) => {
		let allFieldsValid = true;

		const $stepRequiredElements = $form.find('.nf-element[aria-required="true"]');

		$stepRequiredElements.each((i, item) => {
			const $item = $(item);
			if ($item.is(':checkbox')) {
				const name = $item.attr('name');
				if ($form.find(`input[name="${name}"]:checked`).length === 0) {
					allFieldsValid = false;
					return false;
				}
			} else if ($item.val() === '' || $item.attr('aria-invalid') === 'true') {
				allFieldsValid = false;
				return false;
			}
		});

		const $btnNextStep = $form.find('.nf-next-previous .nf-next');
		const $btnSubmit = $form.find('.submit-wrap .nf-element.button');
		if (allFieldsValid) {
			$btnNextStep.prop('disabled', false);
			$btnSubmit.prop('disabled', false);
		} else {
			$btnNextStep.prop('disabled', true);
			$btnSubmit.prop('disabled', true);
		}
	};

	$(document).on('nfFormReady', function(e, ninjaform) {
		if (ninjaform.model.attributes.wrapper_class === 'multi-step-form') {
			const $form = $(ninjaform.el);

			checkFormPartValidity($form);

			$form.on('input', 'input[type="text"], input[type="email"], input[type="tel"], select, textarea', () => {
				checkFormPartValidity($form);
			});

			$form.on('change', 'input[type="checkbox"], input[type="radio"]', () => {
				checkFormPartValidity($form);
			});

			$(document).on('click', '.nf-next-previous .nf-next, .nf-next-previous .nf-prev', () => {
				checkFormPartValidity($form);
			});
		}
	});
}

export default multiStepFormAddition;
