/* global themeParams */

/**
 * External Dependencies
 */
import 'bootstrap/js/dist/modal';


/**
 * Modules
 */
import './modules/hide-preloader';
import updateStyleVariables from './modules/resize-dependent';
import modalVideo from './modules/modal-video';
import modalYoutubeVideo from './modules/modal-youtube-video';
import modal from './modules/modal';
import multistepFormAddition from './modules/multistep-form-addition';
import gTranslateObserver from './modules/gtranslate-observer';

const addAdditionalWrapper = () => {
	const siteBlocks = document.querySelector('.wp-site-blocks');
	const mapLoaderContainer = document.querySelector('.map-loader-lottie');
	const innerWrapper = document.createElement('div');
	innerWrapper.className = 'wp-site-blocks__inner';
	while (siteBlocks.firstChild) {
		innerWrapper.appendChild(siteBlocks.firstChild);
	}
	siteBlocks.appendChild(innerWrapper);

	if (mapLoaderContainer) {
		const lottiePlayer = document.createElement('lottie-player');
		lottiePlayer.setAttribute('id', 'mapPreloader');
		lottiePlayer.setAttribute('src', `${themeParams.themeUrl}/dist/lottie/preloader.json`);
		lottiePlayer.setAttribute('background', 'transparent');
		lottiePlayer.setAttribute('speed', '1');
		lottiePlayer.setAttribute('loop', '');
		lottiePlayer.setAttribute('autoplay', ''); // If you want it to play immediately after being added
		const mapLoaderContainer = document.querySelector('.map-loader-lottie');
		mapLoaderContainer.appendChild(lottiePlayer);
	}
};

const admin = () => {
	setTimeout(() => {
		updateStyleVariables();
	}, 2000);
};

const front = () => {
	const $body = $('body');
	$body.removeClass('scroll-off');
	addAdditionalWrapper();
	updateStyleVariables();
	modalVideo();
	modalYoutubeVideo();
	modal();
	multistepFormAddition();
	gTranslateObserver();
};

$(() => {
	if (
		$('body').hasClass('wp-admin') ||
		$('body').hasClass('block-editor-iframe__body')
	) {
		admin();
	} else {
		front();
	}
});
